<template>
  <div>
    <div class="my-30 py-40 center" v-if="!liveEventList">
      <b-spinner type="grow"></b-spinner>
      <p class="mt-5">{{ $t('alert_please_wait') }}</p>
    </div>
    <div v-else>
      <div :class="`bg-white d-flex ${isWide ? 'px-20 py-10 justify-content-between' : 'p-5 flex-column'}`">
        <div class="w-100 d-flex justify-content-between vertical-center" v-if="!isWide && !visibleFilters">
          <div class="w-100 mr-5 box-search d-flex justify-content-between vertical-center">
            <i class="ml-5 fa fa-search font-20 color-icon" />
            <input class="w-100 px-5" v-model="keyword" @keydown.enter.exact.prevent="refreshData" />
            <v-btn class="mx-2" icon small @click="keyword = ''; refreshData();" :disabled="!keyword">
              <i :class="`font-20 flaticon-circle ${keyword.length > 0 ? 'color-pink' : ''}`"></i>
            </v-btn>
            <v-btn class="m-1 button-round-20" @click="refreshData()">
              <span class="font-12">{{ $t('find') }}</span>
            </v-btn>
          </div>
          <v-btn icon @click="visibleFilters = true">
            <img :src="assets.filter" :height="40" />
          </v-btn>
        </div>
        <div data-app :class="isWide ? 'w-25' : 'w-100'" v-if="isWide || visibleFilters">
          <div class="mt-5 d-flex justify-content-between vertical-center">
            <span :class="`my-2 ${isMobile ? 'font-12' : 'font-15'} font-bold`">{{ $t('event_name') }}</span>
            <v-btn text small @click="keyword = ''; refreshData();" v-if="keyword">
              <span class="font-12">{{ $t('clear') }}</span>
            </v-btn>
          </div>
          <input class="w-100 mt-1 input-normal" v-model="keyword" @keydown.enter.exact.prevent="refreshData" />
          <div class="mt-5 d-flex justify-content-between vertical-center">
            <span :class="`my-2 ${isMobile ? 'font-12' : 'font-15'} font-bold`">{{ $t('address') }}</span>
            <v-btn text small @click="address = ''; refreshData();" v-if="address">
              <span class="font-12">{{ $t('clear') }}</span>
            </v-btn>
          </div>
          <input class="w-100 mt-1 input-normal" v-model="address" @keydown.enter.exact.prevent="refreshData" />
          <div class="mt-5 d-flex justify-content-between vertical-center">
            <span :class="`my-2 ${isMobile ? 'font-12' : 'font-15'} font-bold`">{{ $t('start_date') }}</span>
            <v-btn text small @click="datePicker = null; refreshData();" v-if="datePicker">
              <span class="font-12">{{ $t('clear') }}</span>
            </v-btn>
          </div>
          <DatePicker
            class="mt-1 w-100"
            v-model="datePicker"
            format="ddd, DD MMM"
            type="date"
            :editable="false"
            :clearable="false"
          />
          <div class="mt-5 d-flex justify-content-between vertical-center">
            <span :class="`my-2 ${isMobile ? 'font-12' : 'font-15'} font-bold`">{{ $t('price') }}</span>
            <v-btn text small @click="priceRange = [0, 500]; refreshData();" v-if="priceRange && priceRange.length === 2 && (priceRange[0] > 0 || priceRange[1] < 500)">
              <span class="font-12">{{ $t('clear') }}</span>
            </v-btn>
          </div>
          <v-range-slider
            v-model="priceRange"
            hide-details
            :min="0"
            :max="500"
            :step="10"
            color="#B91AA8"
            thumb-color="#B91AA8"
          />
          <div class="mt-2 d-flex justify-content-between">
            <div class="d-flex flex-column">
              <span :class="isMobile ? 'font-10' : 'font-12'">{{ $t('minimum') }}</span>
              <span :class="`mt-1 input-normal ${isMobile ? 'font-12 w-70px' : 'font-15 w-80px'}`">{{ getPriceFormat(priceRange[0] * 100, true) }}</span>
            </div>
            <div class="d-flex flex-column">
              <span :class="isMobile ? 'font-10' : 'font-12'">{{ $t('maximum') }}</span>
              <span :class="`mt-1 input-normal ${isMobile ? 'font-12 w-70px' : 'font-15 w-80px'}`">{{ getPriceFormat(priceRange[1] * 100, true) }}</span>
            </div>
          </div>
          <div class="mt-5 d-flex justify-content-between vertical-center">
            <span :class="`my-2 ${isMobile ? 'font-12' : 'font-15'} font-bold`">{{ $t('genre') }}</span>
            <v-btn text small @click="selectedGenreIndex = -1; refreshData();" v-if="selectedGenreIndex != -1">
              <span class="font-12">{{ $t('clear') }}</span>
            </v-btn>
          </div>
          <div class="mt-1 w-100 position-relative vertical-center">
            <select class="w-100 input-normal" v-model="selectedGenreIndex">
              <option :class="isMobile ? 'font-12' : 'font-15'" :value="index" v-for="(genre, index) in genreParent" :key="(genre, index)">{{ genre }}</option>
            </select>
            <i :class="`mx-5 position-absolute right-0 ${isMobile ? 'font-12' : 'font-15'} fa fa-angle-down`" />
          </div>
          <div class="mt-5 d-flex justify-content-between vertical-center" v-if="selectedGenreIndex >= 0 && selectedGenreIndex <= 4">
            <span :class="`my-2 ${isMobile ? 'font-12' : 'font-15'} font-bold`">{{ $t('select_event_genre') }}</span>
            <v-btn text small @click="selectedGenreChildren = getGenreChildren()">
              <span class="font-12">{{ $t('select_all') }}</span>
            </v-btn>
          </div>
          <div class="d-flex flex-column">
            <div v-for="(genre, index) in getGenreChildren()" :key="(genre, index)">
              <input :id="`genre_${index}`" :value="genre" type="checkbox" v-model="selectedGenreChildren">
              <label :class="`m-2 ${isMobile ? 'font-12' : 'font-15'}`" :for="`genre_${index}`">{{ genre }}</label><br>
            </div>
          </div>
          <div class="mt-5 d-flex justify-content-end">
            <v-btn class="m-1 button-round-20" @click="refreshData()">
              <span class="font-12">{{ $t('apply_filters') }}</span>
            </v-btn>
          </div>
        </div>
        <div :class="isWide ? 'pl-10 w-75' : 'w-100'" v-if="isWide || !visibleFilters">
          <div v-if="items.length > 0">
            <div :class="isWide ? 'm-5' : 'my-2'" v-for="(item, index) in items" :key="(item, index)">
              <div :class="`w-100 ${isWide ? 'd-flex' : ''}`">
                <div :class="`pointer ${isWide ? 'w-25' : 'w-100'}`" @click="gotoEventDetail(item)">
                  <v-img class="rounded event-image" :src="hostname === 'localhost' && FOR_MA ? index % 2 === 0 ? assets.testImage1 : assets.testImage2 : item.photoUrl" />
                </div>
                <div :class="`${isWide ? 'mx-10 my-5 w-75' : 'm-5'}`">
                  <span :class="`font-bold ${isMobile ? 'font-15' : 'font-18'}`">{{ item.name }}</span>
                  <div class="mt-2 vertical-center">
                    <i :class="`fa fa-calendar-alt color-icon ${isMobile ? 'font-12' : 'font-15'}`"></i>
                    <span :class="`ml-2 ${isMobile ? 'font-12' : 'font-15'}`">{{ getDateStringFromTimestamp(item.startAt, 'ddd, DD MMM • hh:mm A') }}</span>
                  </div>
                  <div class="mt-2 vertical-center">
                    <i :class="`flaticon2-pin-1 color-icon ${isMobile ? 'font-12' : 'font-15'}`"></i>
                    <span :class="`ml-2 ${isMobile ? 'font-12' : 'font-15'}`">{{ item.hideAddress ? $t('private') : getCountryCityFromAddress(item.address) }}</span>
                  </div>
                  <div class="mt-2 box-pink-light">
                    <span :class="`${isMobile ? 'font-12' : 'font-15'} color-pink`">{{ item.genres.join('\n') }}</span>
                  </div>
                  <div class="my-2">
                    <span :class="`ellipsis-4 ${isMobile ? 'font-12' : 'font-15'}`" :title="item.description">{{ item.description }}</span>
                  </div>
                  <VueCountdown class="mt-5" :time="item.countDownTime" v-if="item.countDownTime && item.countDownTime > 0">
                    <template slot-scope="props">
                      <div :class="`${isMobile ? 'w-200px' : 'w-250px'} d-flex justify-content-between box-event-count-down`">
                        <div :class="`${isMobile ? 'w-45px' : 'w-55px'} d-flex flex-column text-center`">
                          <span :class="`${isMobile ? 'font-12' : 'font-15'} font-event-count-down-time`">{{ zeroPad(props.days, 2) }}</span>
                          <span :class="`${isMobile ? 'font-6' : 'font-8'} font-event-count-down-label`">{{ $t(props.days > 1 ? 'days' : 'day') }}</span>
                        </div>
                        <span :class="`${isMobile ? 'font-12' : 'font-15'} font-event-count-down-time`" :style="`margin-top: ${isMobile ? '-1px;' : '-2px;'}`">&#58;</span>
                        <div :class="`${isMobile ? 'w-45px' : 'w-55px'} d-flex flex-column text-center`">
                          <span :class="`${isMobile ? 'font-12' : 'font-15'} font-event-count-down-time`">{{ zeroPad(props.hours, 2) }}</span>
                          <span :class="`${isMobile ? 'font-6' : 'font-8'} font-event-count-down-label`">{{ $t(props.hours > 1 ? 'hours' : 'hour') }}</span>
                        </div>
                        <span :class="`${isMobile ? 'font-12' : 'font-15'} font-event-count-down-time`" :style="`margin-top: ${isMobile ? '-2px;' : '-2px;'}`">&#58;</span>
                        <div :class="`${isMobile ? 'w-45px' : 'w-55px'} d-flex flex-column text-center`">
                          <span :class="`${isMobile ? 'font-12' : 'font-15'} font-event-count-down-time`">{{ zeroPad(props.minutes, 2) }}</span>
                          <span :class="`${isMobile ? 'font-6' : 'font-8'} font-event-count-down-label`">{{ $t(props.minutes > 1 ? 'minutes' : 'minute') }}</span>
                        </div>
                        <span :class="`${isMobile ? 'font-12' : 'font-15'} font-event-count-down-time`" :style="`margin-top: ${isMobile ? '-2px;' : '-2px;'}`">&#58;</span>
                        <div :class="`${isMobile ? 'w-45px' : 'w-55px'} d-flex flex-column text-center`">
                          <span :class="`${isMobile ? 'font-12' : 'font-15'} font-event-count-down-time`">{{ zeroPad(props.seconds, 2) }}</span>
                          <span :class="`${isMobile ? 'font-6' : 'font-8'} font-event-count-down-label`">{{ $t(props.seconds > 1 ? 'seconds' : 'second') }}</span>
                        </div>
                      </div>
                    </template>
                  </VueCountdown>
                  <div class="mt-2 d-flex justify-content-between vertical-center">
                    <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`" v-if="(item.ticketCount || 0) > 0">{{ $t('from') }}&nbsp;{{ getPriceFormat(item.ticketMinPrice) }}</span>
                    <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`" v-else>&nbsp;</span>
                    <v-btn class="button-pink-light-small" @click="gotoEventDetail(item)">
                      <span :class="`${isMobile ? 'font-10' : 'font-12'} color-pink`">{{ $t('ticket') }}<i :class="`${isMobile ? 'font-10' : 'font-12'} ml-2 color-pink fa fa-angle-right`"></i></span>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="my-30 py-40 center" v-else>
            <p>{{ $t('no_data_found') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .box-event-count-down {
    padding: 5px !important;
    background-color: transparent !important;
    border: 2px solid #BBB2B2 !important;
  }
  select {
    outline: none !important;
  }
  input[type="checkbox"] {
    accent-color: #B91AA8 !important
  }
</style>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import { isFutureTimestamp, getDateStringFromTimestamp, getDateStringFromDate, getCountryCityFromAddress, getEventParam, zeroPad, getPriceFormat } from '../../../functions';

import testImage1 from '@/assets/images/test_image1.jpg';
import testImage2 from '@/assets/images/test_image2.jpg';
import filter from '@/assets/images/filter.png';

export default {
  name: 'LiveEvents',
  components: {
    VueCountdown,
    DatePicker
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    isWide() {
      return this.$store.state.isWide;
    },
    liveEventList() {
      return this.$store.state.liveEventList;
    }
  },
  watch: {
    liveEventList() {
      this.refreshData();
    },
    selectedGenreIndex() {
      this.selectedGenreChildren = [];
    }
  },
  data() {
    return {
      assets: {
        testImage1,
        testImage2,
        filter
      },
      genreParent: ['Festivals', 'Clubs', 'Concerts', 'Party', 'Kultur', 'Food & Drinks', 'Sportevents', 'Happening', 'Fairs & Conferences', 'Afterwork'],
      hostname: window.location.hostname,
      visibleFilters: false,
      keyword: '',
      address: '',
      datePicker: null,
      selectedGenreIndex: -1,
      selectedGenreChildren: [],
      priceRange: [0, 200],
      items: []
    };
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    getDateStringFromTimestamp,
    getCountryCityFromAddress,
    getPriceFormat,
    zeroPad,
    gotoEventDetail(eventInfo) {
      this.$router.push(`/e/${getEventParam(eventInfo)}`);
    },
    getGenreChildren() {
      switch (this.selectedGenreIndex) {
        case 0: return ['Rock', 'Indie', 'Alternativ', 'Elektro', 'Techno', 'House', 'Hip Hop', 'RnB', 'Gay & Lesbian', 'Reggae', 'Dancehall', 'Latino', 'Reggeaton', 'Türkisch'];
        case 1: return ['Rock', 'Indie', 'Alternativ', 'Elektro', 'Techno', 'House', 'Hip Hop', 'RnB', 'Gay & Lesbian', 'Reggae', 'Dancehall', 'Charts', 'Deutsche Welle', 'Latino', 'Reggeaton', 'Türkisch'];
        case 2: return ['Hip Hop', 'Blues', 'Country', 'Electronic', 'Folk', 'Gospel', 'Hard & Heavy', 'Independent', 'Jazz', 'Latin', 'Latino', 'Reggeaton', 'Türkisch'];
        case 3: return ['Rock', 'Indie', 'Alternativ', 'Elektro', 'Techno', 'House', 'Hip Hop', 'RnB', 'Gay & Lesbian', 'Reggae', 'Dancehall', 'Latino', 'Reggeaton', 'Türkisch'];
        case 4: return ['Theater', 'Ausstellungen', 'Designmärkte', 'Flohmarkt', 'Kino', 'Strassenfeste', 'Latino', 'Reggeaton', 'Türkisch'];
      }
      return [];
    },
    refreshData() {
      if (this.liveEventList) {
        const keyword = this.keyword.toLowerCase();
        const list = this.liveEventList.filter(element => this.filterEvent(element, keyword));
        const openList = list.filter(element => !isFutureTimestamp(element.startAt)).sort((a, b) => (a.endAt.seconds > b.endAt.seconds) ? 1 : -1);
        const futureList = list.filter(element => isFutureTimestamp(element.startAt)).sort((a, b) => (a.startAt.seconds > b.startAt.seconds) ? 1 : -1);
        futureList.filter(element => element.startAt && element.displayCountDown === true).forEach(info => {
          info['countDownTime'] = info.startAt.seconds * 1000 - new Date().getTime();
        });
        this.items = openList.concat(futureList);
      } else {
        this.items = [];
      }
      this.visibleFilters = false;
    },
    filterEvent(eventInfo, keyword) {
      if (keyword && !eventInfo.name.toLowerCase().includes(keyword)) {
        return false;
      }
      const address = this.address.toLowerCase();
      if (address && !eventInfo.address.toLowerCase().includes(address)) {
        return false;
      }
      if (this.datePicker && eventInfo.startAt && getDateStringFromTimestamp(eventInfo.startAt, 'YYYYMMDD_HHmmss') < getDateStringFromDate(this.datePicker)) {
        return false;
      }
      if (this.priceRange && this.priceRange.length === 2) {
        const ticketMinPrice = eventInfo.ticketMinPrice || 0;
        if (ticketMinPrice < this.priceRange[0] * 100 || ticketMinPrice > this.priceRange[1] * 100) {
          return false;
        }
      }
      if (eventInfo.genres && eventInfo.genres.length > 0 && this.selectedGenreIndex >= 0 && this.selectedGenreIndex <= 9) {
        const genres = [];
        const genreParent = this.genreParent[this.selectedGenreIndex];
        if (this.selectedGenreIndex > 4) {
          genres.push(genreParent);
        } else {
          this.selectedGenreChildren.forEach(element => {
            genres.push(`${genreParent} / ${element}`);
          });
        }
        if (!genres.includes(eventInfo.genres[0])) {
          return false;
        }
        if (eventInfo.genres.length > 1 && !genres.includes(eventInfo.genres[1])) {
          return false;
        }
      }
      return true;
    }
  }
};
</script>